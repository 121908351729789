* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-family: "Roboto Mono", monospace;
  font-size: 13px;
  background-color: #131316;
  color: #e0e0e0;
}

a {
  color: #b3c6ff;
}

body {
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb:hover {
  background: white;
} */
